@import "src/styles";

.Sidebar {
  position: relative;
  min-width: rem(250px);
  min-height: calc(100vh - #{$header-height});

  &_withControls {
    min-height: calc(100vh - #{$header-height} - #{$controls-bar-height});
  }

  &__List {
    $listMargin: rem(55px);

    @include flexbox($direction: column, $gap: rem(20px));
    position: sticky;
    top: calc(#{$header-height} + #{$listMargin});
    left: 0;
    margin-top: $listMargin;
    list-style-type: none;
    padding-left: 0;

    &_withControls {
      top: calc(#{$header-height} + #{$listMargin} + #{$controls-bar-height});
    }
  }

  &__Element {
    width: 90%;
    height: rem(50px);
    padding-left: 8%;
    padding-right: rem(8px);
    border-bottom-right-radius: rem(6px);
    border-top-right-radius: rem(6px);

    &Button {
      @include flexbox($alignItems: center, $justifyContent: space-between);
      @include icon($width: rem(20px), $height: rem(20px));
      width: 100%;
      height: 100%;
      color: $semi-dark-gray-color;
      font-family: $heading-font-family;

      &_active {
        color: $white-color;
        font-weight: bold;
      }
    }

    &Link {
      &_active {
        pointer-events: none
      }
    }

    &Label {
      @include flexbox($alignItems: center, $gap: rem(15px));
    }

    &Badge {
      padding: rem(5px);
      min-width: rem(22px);
      border-radius: rem(20px);
      color: $white-color;
      text-align: center;
      font-size: (13px);
      font-weight: bold;

      &_success {
        background: $success-color;
        min-width: rem(22px);
      }

      &_warning {
        background: $notification-color;
      }
    }

    &_active {
      background: $app-accent-gradient;

      svg {
        fill: $white-color;
      }
    }

    &_skeleton {
      margin-top: 8px;
      margin-bottom: 8px;
      height: rem(35px);
    }
  }
}
