@import "src/styles";
@import "../Tile/styles.module";

.Tiles {
  /**
   * Don't show redundant padding on loading
   */
  &_fetching {
    padding: 0;
  }

  /**
  * Allow to see shadows from children Tiles and don't show own background
  */
  &_wrapping {
    @extend .Tiles_fetching;
    overflow: visible;
    background: none;
    box-shadow: none;

    /**
     * Makes the content-wrapping div in the Tile component act as the list
     */
    & > div {
      @include flexbox($direction: column, $gap: rem(15px));
    }
  }
}
