@import "src/styles/index";

.WeeklyOutreachTile {
  &__Timespan {
    margin: rem(20px);

    &Title {
      color: $semi-dark-gray-color;
      font-size: rem(13px);
    }

    &Weeks {
      font-size: rem(15px);
      font-weight: 450;
    }

    &Dates {
      color: $light-gray-color;
      font-size: rem(13px);
    }
  }

  &__ReachOut {
    margin-top: rem(20px);
  }
}
