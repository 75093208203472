@import "src/styles/index";

.MessagesResetModal {
  &__Empty {
    @include icon($width: rem(35px), $height: rem(35px), $fill: $semi-dark-gray-color);
    margin-top: rem(30px);
    font-size: rem(16px);
    color: $semi-dark-gray-color;
    text-align: center;

    a {
      color: $dark-gray-color;
      text-decoration-line: underline;
      text-underline-offset: 3px;
    }
  }
}
