@import "src/styles";

.TodoApp {
  &__ContentContainer {
    @include flexbox();
  }

  &__Content {
    flex-grow: 1;
    background-color: $light-gray-color;
  }
}
