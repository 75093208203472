@import "src/styles";

.Tooltip {
  &__Trigger {
    @include flexbox($alignItems: center, $gap: rem(10px));
    width: min-content;
    cursor: help;
  }

  &__Content {
    padding: rem(10px) rem(15px);
    border-radius: rem(15px);
    font-size: rem(14px);

    svg {
      margin-left: rem(3px);
      margin-right: rem(3px);
      vertical-align:top;
    }
  }
}

:global(.tooltip-content) {
  background-color: $dark-gray-color;
  color: $white-color;
  border: none;
  border-radius: rem(15px) !important;
}

:global(.tooltip-arrow) {
  color: $black-color;
  stroke: $black-color;
}
