@import "src/styles";

@mixin controlLabel {
  font-size: rem(15px);
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
}

.ControlsBar {
  @include flexbox($alignItems: center, $justifyContent: center);
  position: relative;
  height: $controls-bar-height;
  background-color: $light-gray-color;
  z-index: 2;

  &__Title {
    @include flexbox($alignItems: flex-end, $gap: rem(10px));
    position: absolute;
    left: rem(20px);
    top: rem(10px);
    color: $semi-dark-gray-color;
    font-size: rem(20px);

    svg {
      stroke: $semi-dark-gray-color;
      fill: $semi-dark-gray-color;
    }
  }

  &__Form {
    display: grid;
    grid-template-columns: 2fr 4fr 3fr;
    align-items: center;
    justify-items: center;
    gap: rem(30px);
    padding: rem(5px);
    height: 100%;
  }

  &__VerticalControls {
    @include flexbox($alignItems: center, $direction: column, $gap: rem(10px));
  }

  &__HorizontalControls {
    @include flexbox($alignItems: center, $justifyContent: center, $gap: rem(10px));
  }

  &__ErrorMessage {
    grid-column: 2 / 3;
    margin-bottom: rem(15px);
  }

  &_sticky {
    position: sticky;
    top: 0;
    left: 0;
  }
}
