@import "src/styles/index";

.MessagesTable {
  min-width: rem(650px);

  th, td {
    padding: rem(8px);
    min-width: rem(100px);
  }

  tr {
    background-color: $table-background-color;
  }

  p {
    margin: 0;

    span {
      text-align: right;
      font-size: rem(14px);
      color: $semi-dark-gray-color;
    }
  }

  &__Messages {
    //@include flexbox($direction: column, $gap: rem(50px));
  }

  &__Message {
    display: grid;
    grid-template-columns: 1fr rem(50px);
    grid-template-rows: 2fr 1fr;
    gap: rem(15px);

    &:not(:first-child) {
      border-top: solid white 2px;
      padding-top: 25px;
    }

    &:not(:last-child) {
      padding-bottom: 25px;
    }

    &Data {
      grid-row: 1 / -1;
    }

    &Text {
      grid-row: 1;
      @include flexbox($direction: column, $gap: rem(10px));
    }

    &Meta {
      grid-row: 2;
      @include flexbox($alignItems: center, $justifyContent: space-between);
    }

    &Reset {
      @include icon($width: rem(25px), $height: rem(25px), $fill: $no-sign-in-color);
      grid-row: 1 / -1;
    }
  }

  &__ShowToggle {
    @include flexbox($justifyContent: center);
    margin-top: rem(15px);
  }
}
