@import "src/styles";

.ScreenError {
  @include flexbox($alignItems: center, $justifyContent: center,  $direction: column, $gap: rem(20px));
  width: 100vw;
  height: 100vh;

  &__Tile {
    width: 70%;
    min-height: rem(200px);
  }
}
