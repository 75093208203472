@import "src/styles";

.ActionsGroup {
  @include flexbox($alignItems: center, $gap: rem(15px));

  &_left {
    justify-content: flex-start;
  }

  &_center {
    justify-content: center;
  }

  &_right {
    justify-content: flex-end;
  }
}
