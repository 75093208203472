@import "src/styles/index";

.AssessmentsTable {
  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;

    th, td {
      text-align: left;
      font-size: rem(13px);
    }

    thead {
      tr {
        svg {
          width: rem(18px);
        }

        &:first-child {
          border-bottom: 4px solid transparent;

          th {
            &:not(:first-child):not(:nth-child(2)) {
              width: 240px;

              @media(max-width: 1650px) {
                & {
                  width: 190px
                }
              }

              @media(max-width: 1550px) {
                & {
                  width: 170px
                }
              }

              @media(max-width: 1450px) {
                & {
                  width: 150px
                }
              }

              @media(max-width: 1300px) {
                & {
                  width: auto;
                }
              }
            }
          }
        }

        &:nth-child(2) {
          svg {
            width: rem(16px);
            height: rem(16px);
          }
        }
      }

      th {
        font-weight: normal;
        color: $semi-dark-gray-color;

        svg {
          stroke: $semi-dark-gray-color;
        }

        &:first-child {
          width: rem(40px);
        }

        &:nth-child(2) {
          & > .AssessmentsTable__Header {
            justify-content: left;
          }
        }
      }

      &::after {
        content: "";
        display: block;
        height: rem(10px);
        width: 100%;
        background: white;
      }
    }

    tbody {
      td {
        padding-top: rem(20px);
        padding-bottom: rem(20px);
        border-top: rem(1px) solid $light-gray-color;
        border-bottom: rem(1px) solid $light-gray-color;
        line-height: 1.2;
        text-align: center;

        &:nth-child(2) {
          text-align: left;
        }
      }
    }
  }

  &__Tools {
    @include flexbox($alignItems: center, $justifyContent: flex-end, $gap: rem(30px));
    margin-top: rem(-35px);
    margin-bottom: rem(35px);
    padding-right: rem(30px);

    &Trigger {
      color: $semi-dark-gray-color;
      font-size: rem(13px);
    }
  }

  &__Cell {
    &_connected {
      @include flexbox($alignItems: center, $justifyContent: center, $gap: rem(10px));
      @include icon($width: rem(16px), $height: rem(16px), $fill: $success-color);
    }

    &_success {
      color: $success-color;
    }

    &_danger {
      color: $danger-color;
    }
  }

  &__Header {
    @include flexbox($alignItems: center, $justifyContent: center, $gap: rem(10px));

    &_live {
      svg {
        stroke: $app-accent-color !important;
      }
    }
  }

  &__Select {
    input[type=checkbox]:checked + svg {
      fill: $app-accent-color;
    }

    input[type=checkbox]:indeterminate + svg {
      fill: $app-accent-color;
    }
  }
}

