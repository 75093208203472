@import "src/styles";
@import "src/components/Board/Tile/styles.module";

.WebtextTile {
  display: block;
  position: relative;
  text-decoration: none;
  color: inherit;

  &__Cover {
    display: block;
    width: calc(100% + #{$tile-horizontal-padding} * 2);
    height: calc(100% + #{$tile-bottom-padding});
    margin-top: -$tile-top-padding;
    margin-bottom: -$tile-bottom-padding;
    margin-left: -$tile-horizontal-padding;
    min-height: rem(160px);
    max-height: rem(355px);
    object-fit: cover;
  }

  &__Title {
    position: absolute;
    bottom: rem(7px);
    left: calc(#{$tile-horizontal-padding} - #{rem(15px)});
    width: min-content;
    margin: 0;
    padding: rem(5px) rem(10px);
    font-size: rem(30px);
    font-family: $heading-font-family;
    white-space: pre;
    color: $white-color;
    background-color: hsla(0, 0%, 36%, 0.7);
    border-radius: rem(15px);
  }
}
