@import "src/styles";
@import "../Note/note.module";

.Section {
  position: relative;

  &__Tile {
    &_withNote {
      width: calc(100% - #{$note-width} + #{rem(25px)})
    }
    z-index: 0;
  }

  &__Note {
    position: absolute;
    top: rem(100px);
    right: 0;
    z-index: 1;
  }

  &__Separator {
    margin: rem(35px) 0 0;
    border: none;
    border-top: 3px solid $light-gray-color;
    opacity: 0.5;
  }
}
