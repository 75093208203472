@import "src/styles";

$tile-top-padding: rem(10px);
$tile-horizontal-padding: rem(25px);
$tile-bottom-padding: rem(15px);
$tile-shadow: 1px 2px 7px 3px rgba(0, 0, 0, 0.1);
$tile-border-radius: rem(10px);

.Tile {
  position: relative;
  padding: $tile-top-padding $tile-horizontal-padding $tile-bottom-padding;
  overflow: hidden;
  box-shadow: $tile-shadow;
  border-radius: $tile-border-radius;
  background: $white-color;

  &__Skeleton {
    padding: rem(18px);
  }

  &__Content {
    &_blurred {
      filter: blur(5px);
      pointer-events: none;
    }
  }

  &__Dimmer {
    &_background {
      background-color: $semi-light-gray-color;
    }

    &_error {
      background-color: transparent;
      opacity: 0.6;
      pointer-events: auto;
    }
  }

  &_error {
    min-height: rem(70px);
  }
}
