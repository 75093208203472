@import "src/styles";

.TileHeader {
  @include icon($width: rem(20px), $height: rem(20px));

  h3 {
    @include flexbox($alignItems: center, $gap: rem(15px));
    font-size: rem(16px);
    font-family: $heading-font-family;
  }
}
