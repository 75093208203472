@import "src/styles/index";

.LabeledSlider {
  width: 100%;

  &__InputGroup {
    display: grid;
    grid-template-columns: rem(60px) 1fr rem(80px);
    align-items: baseline;
    justify-items: center;
    gap: rem(10px);
    font-size: rem(14px);
  }

  &__Value  {
    white-space: nowrap;

    span {
      font-size: 0.8em;
    }
  }

  &__Explanation {
    text-align: center;
    color: $semi-dark-gray-color;
    font-size: rem(11px);
    margin-top: rem(7px);
  }
}
