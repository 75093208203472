@import "src/styles/index";

.Notification {
  position: relative;

  &__Link {
    text-decoration: none;
    color: inherit;
  }

  &__Tile {
    display: grid;
    grid-template-columns: 1fr rem(20px);
    align-items: center;
    padding: rem(10px) rem(15px);
    font-size: rem(13px);
  }

  &__Header {
    font-family: $heading-font-family;
    margin: 0;
  }

  &__Message {
    margin: rem(10px) 0 0;
  }

  &__Separator {
    width: 90%;
    margin: rem(10px) auto;
    border-width: 1px;
  }

  &__Badge {
    position: absolute;
    top: rem(2px);
    right: 6%;
    content: '';
    width: rem(10px);
    height: rem(10px);
    border-radius: 50%;
    background-color: $notification-color;
  }
}
