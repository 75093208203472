@import "src/styles";

.ErrorMessage {
  @include flexbox($alignItems: center, $gap: rem(15px));
  max-width: 100%;

  &__Icon {
    &_small {
      @include icon($width: rem(40px), $height: rem(40px), $fill: $danger-color);
    }

    &_medium {
      @include icon($width: rem(45px), $height: rem(45px), $fill: $danger-color);
    }

    &_large {
      @include icon($width: rem(50px), $height: rem(50px), $fill: $danger-color);
    }

    &_huge {
      @include icon($width: rem(70px), $height: rem(70px), $fill: $danger-color);
    }
  }

  &__Content {
    color: $danger-color;
    line-height: 1.2;

    p {
      margin-top: rem(8px);
      margin-bottom: rem(8px);
      white-space: pre-line;
    }

    &_small {
      font-size: rem(15px);
    }

    &_medium {
      font-size: rem(16px);
    }

    &_large {
      font-size: rem(17px);
    }

    &_huge {
      font-size: rem(21px);
    }
  }

  &__Title {
    font-weight: 500;
  }

  &_horizontal {
    flex-direction: row;
  }

  &_vertical {
    flex-direction: column;
    text-align: center;
  }
}
