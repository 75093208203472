@import "src/styles";

.SlidersGroup {
  @include flexbox($direction: column, $alignItems: center, $justifyContent: center, $gap: rem(10px));

  &__Label {
    font-size: rem(20px);
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 0;
  }

  &__List {
    @include flexbox($alignItems: center, $justifyContent: center, $wrap: wrap, $gap: rem(15px));
    list-style-type: none;
    padding-left: 0;
  }

  &__Item {
    width: 100%;
  }
}
