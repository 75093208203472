@import "src/styles/index";
@import 'src/components/ControlsBar/styles.module';

.AnswersStateSliders {
  @include flexbox($alignItems: center, $direction: column);

  h4 {
    @include controlLabel;
  }
}
