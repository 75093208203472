@import "src/styles/index";

.Notifications {
  &__Trigger {
    position: relative;
    @include icon($width: rem(18px), $height: rem(18px), $fill: $white-color);

    &_open svg {
      fill: $app-accent-color;
    }
  }

  &__Badge {
    position: absolute;
    top: 0;
    right: 0;
    content: '';
    width: rem(8px);
    height: rem(8px);
    border-radius: 50%;
    background-color: $notification-color;
  }

  &__Loader {
    position: absolute;
    top: 0;
    right: 15%;
    @include icon($width: rem(10px), $height: rem(10px));
  }

  &__Board {
    padding: rem(15px) 0;
    gap: 0;
  }
}

:global(.notifications-content) {
  min-width: rem(400px);
  background-color: $black-color;
  color: $white-color;
  border: none;
  border-radius: rem(15px) !important;
}

:global(.notifications-arrow) {
  color: $black-color;
  stroke: $black-color;
}
