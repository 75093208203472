@import "src/styles";

.ActionButton {
  @include flexbox($alignItems: center, $gap: rem(7px));
  padding: .5em 1.8em;
  border: none;
  border-radius: rem(5px);
  cursor: pointer;
  background-color: transparent;
  font-family: inherit;
  font-size: rem(17px);
  text-decoration: none;

  a {
    color: inherit;
    text-decoration: none;
  }

  &:disabled {
    background-color: $semi-dark-gray-color;
    cursor: default;
  }

  &_small {
    @include icon($width: rem(20px), $height: rem(20px));
    font-size: rem(14px);
  }

  &_medium {
    @include icon($width: rem(24px), $height: rem(24px));
    font-size: rem(15px);
  }

  &_large {
    @include icon($width: rem(24px), $height: rem(24px));
    font-size: rem(16px);
  }

  &_huge {
    @include icon($width: rem(30px), $height: rem(30px));
    font-size: rem(18px);
  }

  &_bold {
    font-weight: 500;
  }

  &_primary {
    color: $white-color;
    background: $app-accent-gradient;
    text-decoration: none;

    svg {
      fill: $white-color;
      stroke: $white-color;
    }
  }

  &_secondary {
    color: $success-color;
    border: 1px solid $success-color;
    text-decoration: none;

    svg {
      fill: $success-color;
      stroke: $success-color;
    }
  }

  &_dark {
    color: $white-color;
    background-color: $dark-gray-color;
    text-decoration: none;

    &:focus-visible {
      @include outline($color: $notification-color, $width: 2px);
    }

    svg {
      fill: $white-color;
      stroke: $white-color;
    }
  }

  &_none {
    padding: 0;
    color: $black-color;

    svg {
      fill: $black-color;
      stroke: $black-color;
    }
  }
}
