@import "src/styles/index";
@import 'src/components/ControlsBar/styles.module';

.ZScoreSlider {
  @include flexbox($alignItems: center, $direction: column, $gap: rem(5px));
  width: 100%;

  &__LabelGroup {
    @include flexbox($alignItems: center, $gap: rem(6px));
    @include icon($width: rem(15px), $height: rem(15px));
  }

  &__Label {
    @include controlLabel;
  }

  &__InputGroup {
    @include flexbox($alignItems: center, $direction: column, $gap: rem(5px));
    width: 100%;

    span:first-child {
      width: rem(38px);
    }
  }
}
