@import "src/styles";

.Header {
  @include flexbox($alignItems: center, $justifyContent: space-between);
  position: sticky;
  top: 0;
  left: 0;
  height: $header-height;
  padding-left: rem(25px);
  padding-right: rem(25px);
  color: $white-color;
  background-color: $black-color;
  z-index: 2;
  font-family: $heading-font-family;

  &__LabelContainer {
    @include flexbox($alignItems: center);
    @include icon($width: rem(22px), $height: rem(22px), $fill: $white-color);
    font-size: rem(15px);
    font-weight: 300;
    height: 100%;
  }

  &__Icon {
    cursor: pointer;
    user-select: none;

    g {
      fill: $white-color;
    }
  }

  &__Label {
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    user-select: none;
    margin-left: rem(10px);
  }

  &__Slash {
    margin: 0 rem(10px);
    color: $app-accent-color;
  }

  &__CourseName {
    color: $app-accent-color;
    text-decoration-line: underline;
    text-underline-offset: 4px;
  }

  &__CourseNumber {
    margin-left: rem(10px);
    text-transform: uppercase;
  }

  &__DevInfo {
    margin-left: rem(10px);
    font-size: rem(11px);
    color: $semi-dark-gray-color;
  }

  &__ControlsContainer {
    @include flexbox($alignItems: center, $gap: rem(28px));
    height: 100%;
  }


  &_withControls {
    top: $controls-bar-height;
  }
}
