@import "src/styles/index";
@import 'src/components/ControlsBar/styles.module';

.PivotDatePicker {
  @include flexbox($alignItems: center, $direction: column, $gap: rem(5px));
  width: 85%;

  &__Label {
    @include controlLabel;
  }

  &__Input {
    width: 100%;
    padding: rem(2px) rem(10px);
    text-align: center;
    font-family: inherit;
    font-size: rem(15px);
    border-width: 1px;
    border-radius: rem(5px);
  }

  &__Popper {
    top: -55px !important;
  }

  &__Calendar {
    font-family: inherit;
  }

  &__Controls {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: rem(2px);
    row-gap: rem(5px);
    width: 100%;
  }

  @media(max-width: 1350px) {
    & {
      width: 90%;
    }
  }

  @media(max-width: 1100px) {
    & {
      width: 100%;
    }
  }
}
