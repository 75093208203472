@import "src/styles/utils/to_rem_converter";
@import "src/styles/constants/colors";

@mixin icon(
  $width: rem(20px),
  $height: rem(20px),
  $fill: $dark-gray-color,
  $stroke: $light-gray-color,
  $vertical-alignment: middle
) {
  svg {
    width: $width;
    height: $height;
    fill: $fill;
    stroke: $stroke;
    vertical-align: $vertical-alignment;
  }
}
