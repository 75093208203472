@import "src/styles/index";

.NewWorkEmptyTile {
  &_success {
    color: $success-color;

    svg {
      fill: $success-color;
    }
  }
}
