@import "src/styles";

.ChapterTable {
  table {
    width: 100%;
    border-collapse: collapse;

    th, td {
      text-align: left;
    }

    thead {
      th {
        font-size: rem(13px);
        font-weight: normal;
        color: $semi-dark-gray-color;
        padding-left: rem(8px);
      }

      th:nth-child(1) {
        width: rem(150px);
      }

      th:nth-child(2) {
        width: rem(100px);
      }

      th:nth-child(4) {
        width: rem(80px);
      }

      &::after {
        content: "";
        display: block;
        height: rem(10px);
        width: 100%;
        background: white;
      }
    }

    tbody {
      td {
        padding: rem(15px) rem(10px);
        font-size: rem(13px);
        border-top: rem(1px) solid $light-gray-color;
        border-bottom: rem(1px) solid $light-gray-color;
        line-height: 1.2;
      }

      &:not(:first-of-type) {
        border-top: rem(2px) solid $semi-dark-gray-color;
      }

      &:not(:last-of-type) {
        border-bottom: rem(2px) solid $semi-dark-gray-color;
      }
    }
  }

  &__EmptyCell {
    padding-left: rem(15px);
    font-size: rem(12px);
  }

  &__QuestionCell {
    @include flexbox($direction: column, $gap: rem(5px));

    span {
      color: $semi-dark-gray-color;
      max-width: rem(750px);

      a {
        color: $app-accent-color;
        text-underline-offset: rem(3px);
      }
    }
  }

  &__ReviewButton {
    font-size: rem(14px);
    color: $app-accent-color;
    text-decoration: underline !important;
    text-underline-offset: rem(3px);
  }
}

