@import "src/styles/index";

.SliderInput {
  appearance: none;
  width: 100%;
  height: rem(8px);
  background: $semi-dark-gray-color;
  border-radius: rem(20px);

  @mixin thumb {
    appearance: none;
    width: rem(12px);
    height: rem(12px);
    border: none;
    border-radius: 50%;
    background: $dark-gray-color;
    cursor: pointer;
  }

  &::-webkit-slider-thumb {
    @include thumb;
  }

  &::-moz-range-thumb {
    @include thumb;
  }
}
