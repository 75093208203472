@import url('//fast.fonts.net/cssapi/b78142e0-ec20-49c2-bed2-9d732c8ce9db.css');

$app-font-family: 'Neue Helvetica eText W01';
$thin-app-font-family: 'Neue Helvetica Georgian W82';
$heading-font-family: 'Proxima Nova W01';

body {
  font-family: $app-font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
