@import "src/styles/index";
@import "src/components/Buttons/ActionButton/styles.module";

// TODO Revisit for labels

.InputGroup {
  display: grid;
  grid-template-areas:
          "top top"
          "left center";
  row-gap: rem(5px);
  column-gap: rem(15px);
  align-items: center;
  grid-template-columns: min-content 3fr;
  width: 100%;

  &__Label {
    &_left {
      grid-area: left;
    }

    &_top {
      grid-area: top;
      grid-column: 1 / -1;
    }
  }

  &__Input {
    grid-area: center;
    @include flexbox($alignItems: stretch);
    font-size: rem(13px);
    border-radius: 0;

    input, textarea {
      padding: rem(20px) rem(15px);
      width: 100%;
      min-width: rem(50px);
      border: none;
      border-radius: rem(15px);
      font-family: inherit;
      font-size: inherit;
      resize: none;

      &:focus-visible {
        outline-color: $app-accent-color;
      }

      &:disabled {
        background-color: rgba(239, 239, 239, 0.8);
      }
    }

    span {
      padding: rem(10px) rem(12px);
      border: 1px solid $light-gray-color;
      background-color: $light-gray-color;

      &:first-child {
        border-right: 0;
      }

      &:last-child {
        border-left: 0;
      }
    }

    &_wide {
      grid-column: 1 / -1;
    }
  }

  &_labelCenter {
    align-items: center;
  }

  &_labelStart {
    align-items: start;
  }
}
