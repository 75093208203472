@import "src/styles";

.IconCheckbox {
  position: relative;
  @include icon();

  // Hide checkbox visually but save its accessibility
  // https://www.sarasoueidan.com/blog/inclusively-hiding-and-styling-checkboxes-and-radio-buttons/
  &__Input {
    position: absolute;
    opacity: 0;
    width: rem(20px);
    height: rem(20px);
    cursor: pointer;

    &:focus-visible + svg {
      @include outline($color: black, $width: 2px, $offset: 2px);
    }
  }
}
