@import "src/styles";

.NoteHeader {
  font-family: $heading-font-family;

  h4 {
    margin-top: rem(14px);
    margin-bottom: rem(14px);
  }

  &__Close {
    @include icon($width: rem(18px), $height: rem(18px));
    position: absolute;
    top: rem(5px);
    right: rem(5px);
  }
}
