@import "src/styles";
@import "src/components/Board/Tile/TileParagraph/styles.module";

.WelcomeTile {
  &__Interests {
    @extend .TileParagraph;

    $dash-offset: rem(15px);
    margin-top: rem(-8px);
    padding-left: $dash-offset;
    margin-bottom: 0;
    list-style-type: none;

    li:before {
      content: '-';
      position: absolute;
      margin-left: -$dash-offset;
    }
  }
}
