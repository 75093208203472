@import "src/styles";

.BoardHeader {
  @include flexbox($alignItems: center, $gap: rem(20px));
  @include icon($width: rem(30px), $height: rem(30px));
  color: $black-color;
  font-size: rem(15px);
  font-family: $heading-font-family;

  svg {
    vertical-align: middle;
  }
}
