@import "src/styles";

.RiskTile {
  &__Header {
    margin-bottom: rem(30px);

    span {
      display: inline-block;
      width: rem(16px);
      height: rem(16px);
      border-radius: 50%;
    }
  }

  &__Actions {
    margin-top: rem(20px);
  }
}
