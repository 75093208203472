@import "src/styles";

.SectionHeader {
  @include flexbox($alignItems: center, $justifyContent: space-between);
  font-size: rem(17px);
  font-family: $heading-font-family;

  h3 {
    @include flexbox($alignItems: center, $gap: rem(10px));
  }

  &__Close {
    @include icon($width: rem(20px), $height: rem(20px));
  }
}
