@import "src/styles";

.Dimmer {
  @include flexbox($alignItems: center, $justifyContent: center);
  @include icon($width: rem(70px), $height: rem(70px));
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: rem(20px);
  background-color: $dark-gray-color;
  opacity: 0.5;
  z-index: 1;
  pointer-events: none;
}
