@import "src/styles";

.ScreenLoader {
  @include flexbox($alignItems: center, $justifyContent: center, $direction: column, $gap: rem(15px));
  @include icon($width: rem(120px), $height: rem(120px), $stroke: $app-accent-color);
  width: 100vw;
  height: 100vh;
  background-color: $light-gray-color;

  &__Title {
    color: $semi-dark-gray-color;
    font-size: rem(28px);
    font-weight: 500;
    font-family: $heading-font-family;
  }
}
