@import "src/styles";
@import "src/components/Buttons/ActionButton/styles.module";

.MessageFormTile {
  border: 1px solid $app-accent-color;
  background-color: hsl(88, 27%, 88%);

  &__Form {
    @include flexbox($direction: column, $gap: rem(10px));

    textarea {
      height: 10em;
    }
  }

  &__Names {
    input {
      font-weight: 450;
      background-color: $white-color !important;
    }
  }

  &__Message {
    &_disabled {
      textarea {
        height: 4rem;
      }
    }
  }

  &__Actions {
    margin-top: rem(20px);
  }
}
