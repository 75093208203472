@import "src/styles";

.Pill {
  width: min-content;
  margin: 0;
  padding: .4em .8em;
  white-space: pre;
  color: $white-color;
  font-size: rem(15px);
  background: $on-track-color;
  border-radius: rem(15px);
}
