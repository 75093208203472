$black-color: hsl(0, 0%, 3%);
$white-color: hsl(0, 0%, 100%);
$dark-gray-color: hsl(0, 0%, 12%);
$semi-dark-gray-color: hsl(0, 0%, 58%);
$light-gray-color: hsl(0, 0%, 95%);
$semi-light-gray-color: hsl(0, 0%, 75%);
$notification-color: hsl(44, 87%, 63%);
$danger-color: hsl(11, 80%, 48%);
$success-color: hsl(89, 38%, 45%);

$on-track-color: hsl(197, 83%, 45%);
$low-score-color: hsl(176, 74%, 46%);
$low-completion-color: hsl(47, 94%, 49%);
$never-signed-in-color: hsl(220, 7%, 92%);
$no-sign-in-color: hsl(351, 96%, 59%);

$table-background-color: hsl(220, 12%, 95%);

$app-accent-color: hsl(70, 65%, 44%);
$app-accent-gradient: linear-gradient(90deg, hsl(89, 38%, 49%) 0%, hsl(71, 73%, 46%) 101.1%);

