@import "src/styles";
@import "../Tile/styles.module";

$note-top-padding: rem(5px);
$note-horizontal-padding: rem(20px);
$note-bottom-padding: rem(10px);
$note-width: rem(220px);

.Note {
  position: relative;
  padding: $note-top-padding $note-horizontal-padding $note-bottom-padding;
  overflow: hidden;
  width: $note-width;
  box-shadow: 1px 2px 7px 0 rgba(0,0,0,0.3);
  border-left: rem(5px) solid $notification-color;
  border-radius: $tile-border-radius;
  background-color: $light-gray-color;
}
