@import "src/styles/index";

@mixin riskElement {
  @include flexbox($alignItems: center, $gap: rem(8px));
  color: $semi-dark-gray-color;

  span {
    display: inline-block;
    width: rem(16px);
    height: rem(16px);
    border-radius: 50%;
  }
}

.AssessmentsGraph {
  &__Tooltip {
    @include flexbox($direction: column, $gap: rem(5px));
    padding: rem(15px) rem(22px);
    background: $white-color;
    border-radius: rem(15px);

    &Week {
      font-family: $heading-font-family;
    }

    &Range {
      font-size: rem(13px);
      color: $semi-dark-gray-color;
    }

    &Legend {
      @include flexbox($direction: column, $gap: rem(10px));
      list-style-type: none;
      padding-left: 0;
      margin-top: rem(10px);
      margin-bottom: 0;

      &Element {
        @include riskElement;
        font-size: rem(13px);
      }
    }
  }

  &__Legend {
    @include flexbox($alignItems: center, $gap: rem(20px), $wrap: wrap);
    list-style-type: none;
    padding-left: rem(80px);

    &Element {
      @include riskElement;
      font-size: rem(14px);
    }
  }
}
