@import "src/styles/index";

.UiActionsToggleModal {
  &__Actions {
    @include flexbox($direction: column, $gap: rem(10px));
    list-style-type: none;
    padding-left: 0;
    margin-top: rem(10px);
  }

  &__Action {
    @include flexbox($alignItems: center, $gap: rem(5px));
    border: none;
    cursor: pointer;
    background-color: transparent;
    font-family: inherit;
    font-size: inherit;
  }
}
