@import "src/styles/index";

.EmptyNotification {
  &__Tile {
    @include icon($width: rem(20px), $height: rem(20px), $fill: $success-color);
    color: $success-color;
    background-color: $black-color;
    padding-top: 0;
    padding-bottom: 0;
    text-align: center;
  }
}
